import axios from 'axios';
import clsx from 'clsx';
import { Button } from '../ui/Button';
import {
  FormError,
  formAction$,
  reset,
  useForm,
  valiForm$
} from '@modular-forms/qwik';
import { Input } from '../ui/Input';
import { type Input as ValibotInput, pick } from 'valibot';
import { component$, useSignal, useTask$ } from '@builder.io/qwik';
import { getToken, useAuthDataLoader } from './utils';
import { signinSchema } from './SigninEmailForm';

export type RecoveryForm = ValibotInput<typeof recoverySchema>;

export const recoverySchema = pick(signinSchema, ['password', 'email']);

export const useRecoveryFormAction = formAction$<RecoveryForm>(async values => {
  try {
    const token = await getToken();

    if (!token) {
      throw new FormError<RecoveryForm>(
        'Falha na obtenção do token. Por favor, contate o suporte.'
      );
    }

    await axios.post(
      `${global.ENV.PUBLIC_API_URL}/auth/users/reset_passwords/confirm`,
      values,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new FormError(error);
  }
}, valiForm$(recoverySchema));

export const ChangePasswordForm = component$(
  ({ email, onSuccess$ }: { email?: string; onSuccess$?: () => void }) => {
    const { value: data } = useAuthDataLoader();
    const initialState = useSignal({
      email,
      password: ''
    });

    const action = useRecoveryFormAction();

    const [state, { Form, Field }] = useForm<RecoveryForm>({
      action,
      loader: initialState,
      validate: valiForm$(recoverySchema),
      validateOn: 'touched'
    });

    useTask$(({ track }) => {
      track(() => [action.status]);

      const isSubmitted = action.submitted;

      if (isSubmitted) {
        const { value: ctx } = action;

        if (ctx?.response?.status !== 'error') {
          reset(state);
          onSuccess$?.();
        }
      }
    });

    return (
      <div class="relative flex w-full h-[327px] flex-col items-center justify-start gap-5 mt-8">
        <div class="flex flex-col gap-7 self-stretch">
          <Form class="flex w-full h-full justify-center items-center flex-col gap-5">
            <div class="max-w-[365px] w-full mx-auto">
              <Field name="email">
                {(field, props) => (
                  <input
                    {...props}
                    disabled={state.submitting}
                    type="hidden"
                    value={field.value}
                  />
                )}
              </Field>
              <Field name="password">
                {(field, props) => (
                  <Input
                    {...props}
                    class={clsx('focus:placeholder-neutral-300')}
                    autoComplete="current-password"
                    disabled={state.submitting}
                    errorText={
                      field.touched && state.invalid ? field.error : ''
                    }
                    placeholder="Sua nova senha*"
                    size="lg"
                    state={
                      field.touched && state.invalid
                        ? field.error
                          ? 'error'
                          : 'success'
                        : 'default'
                    }
                    value={field.value}
                    password
                  />
                )}
              </Field>
            </div>
            <Button
              class="max-w-[365px] w-full self-center"
              buttonText={data.changePassword.buttonTitle}
              disabled={state.invalid || state.submitting || !state.dirty}
              loading={state.submitting}
              size="xl"
              type="submit"
              variant="brand"
            />
          </Form>
        </div>
      </div>
    );
  }
);
