import axios from 'axios';
import { Button } from '../ui/Button';
import {
  FormError,
  formAction$,
  reset,
  useForm,
  valiForm$
} from '@modular-forms/qwik';
import { Input } from '../ui/Input';
import { Tracker } from '../../lib/tracker';
import { type Input as ValibotInput, pick } from 'valibot';
import { component$, useSignal, useTask$ } from '@builder.io/qwik';
import { getToken, useAuthDataLoader } from './utils';
import { signinSchema } from './SigninEmailForm';

export type RecoveryForm = ValibotInput<typeof recoverySchema>;

export const recoverySchema = pick(signinSchema, ['email']);

export const useRecoveryFormAction = formAction$<RecoveryForm>(async values => {
  try {
    const token = await getToken();

    if (!token) {
      throw new FormError<RecoveryForm>(
        'Falha na obtenção do token. Por favor, contate o suporte.'
      );
    }

    await axios.post(
      `${global.ENV.PUBLIC_API_URL}/auth/users/reset_passwords/start`,
      values,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new FormError(error?.response?.data, error);
  }
}, valiForm$(recoverySchema));

export const RecoveryEmailForm = component$(
  ({ onSuccess$ }: { onSuccess$?: (email?: string) => void }) => {
    const { value: data } = useAuthDataLoader();
    const messageError = useSignal<string | undefined>();
    const initialState = useSignal({
      email: ''
    });

    const action = useRecoveryFormAction();

    const [state, { Form, Field }] = useForm<RecoveryForm>({
      action,
      loader: initialState,
      validate: valiForm$(recoverySchema),
      validateOn: 'touched'
    });

    useTask$(({ track }) => {
      track(() => [action.status]);

      const isSubmitted = action.submitted;

      if (isSubmitted) {
        const { value: ctx } = action;

        if (ctx?.response?.status === 'error') {
          messageError.value = 'E-mail inválido ou não existe';

          Tracker?.newPasswordEmailSubmitted({
            'code validation status': '500',
            request_path: window.location.pathname
          });
        } else {
          Tracker?.newPasswordEmailSubmitted({
            'code validation status': '200',
            request_path: window.location.pathname
          });

          reset(state);
          onSuccess$?.(action.value?.values.email);
        }
      }
    });

    return (
      <div class="relative flex w-full h-[327px] flex-col items-center justify-start gap-5 mt-8">
        <div class="flex flex-col gap-7 self-stretch">
          <Form class="flex w-full h-full justify-center items-center flex-col gap-5">
            <div class="max-w-[365px] w-full mx-auto">
              <Field name="email">
                {(field, props) => (
                  <Input
                    {...props}
                    class="focus:placeholder-neutral-300"
                    autoComplete="username"
                    disabled={state.submitting}
                    placeholder="Seu e-mail ou usuário*"
                    size="lg"
                    state={
                      (field.touched && state.invalid) || messageError.value
                        ? field.error || messageError.value
                          ? 'error'
                          : 'success'
                        : 'default'
                    }
                    type="email"
                    value={field.value}
                    {...(action.value?.response?.status &&
                      action.value?.response?.status === 'error' && {
                        state: 'error',
                        label:
                          action.value.response.message || messageError.value
                      })}
                  />
                )}
              </Field>
            </div>
            <Button
              class="max-w-[365px] w-full self-center"
              buttonText={data.recovery.buttonTitle}
              disabled={state.invalid || state.submitting || !state.dirty}
              loading={state.submitting}
              size="xl"
              type="submit"
              variant="brand"
            />
          </Form>
        </div>
      </div>
    );
  }
);
