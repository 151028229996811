import axios from 'axios';
import {
  $,
  component$,
  useSignal,
  useTask$,
  useVisibleTask$
} from '@builder.io/qwik';
import { Button } from '../ui/Button';
import {
  FormError,
  formAction$,
  reset,
  useForm,
  valiForm$
} from '@modular-forms/qwik';
import { Input } from '../ui/Input';
import { Tracker } from '../../lib/tracker';
import { type Input as ValibotInput, minLength, object, string } from 'valibot';
import { getToken, useAuthDataLoader } from './utils';

export type RequestForm = ValibotInput<typeof requestCodeSchema>;

export const requestCodeSchema = object({
  email: string(),
  code: string('Por favor informe o código', [
    minLength(6, 'Mínimo de 6 caracteres')
  ])
});

export const useRequestCodeFormAction = formAction$<RequestForm>(
  async values => {
    try {
      const token = await getToken();

      if (!token) {
        throw new FormError<RequestForm>(
          'Falha na obtenção do token. Por favor, contate o suporte.'
        );
      }

      await axios.post(
        `${global.ENV.PUBLIC_API_URL}/auth/users/reset_passwords/check`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      throw new FormError(error?.response?.data, error);
    }
  },
  valiForm$(requestCodeSchema)
);

export const RequestCodeForm = component$(
  ({ email, onSuccess$ }: { email?: string; onSuccess$?: () => void }) => {
    const { value: data } = useAuthDataLoader();
    const messageError = useSignal<string | undefined>();
    const enableRequestCode = useSignal(false);
    const loadingRequestCode = useSignal(false);
    const restartTrigger = useSignal(0);

    const initialState = useSignal({
      email,
      code: ''
    });

    const action = useRequestCodeFormAction();

    const [state, { Form, Field }] = useForm<RequestForm>({
      action,
      loader: initialState,
      validate: valiForm$(requestCodeSchema),
      validateOn: 'touched'
    });

    const handleRequestCode$ = $(async () => {
      loadingRequestCode.value = true;
      await requestCode();
      restartTrigger.value++;
      loadingRequestCode.value = false;
      enableRequestCode.value = false;
    });

    useTask$(({ track }) => {
      track(() => [action.status]);

      const isSubmitted = action.submitted;

      if (isSubmitted) {
        const { value: ctx } = action;

        if (ctx?.response?.status === 'error') {
          messageError.value = 'Código expirado ou inválido';

          Tracker?.newPasswordCodeSubmitted({
            request_path: window.location.pathname,
            'code validation message': '500',
            'code validation status': ctx?.response?.message as string
          });
        } else {
          Tracker?.newPasswordCodeSubmitted({
            request_path: window.location.pathname,
            'code validation message': '200',
            'code validation status': ctx?.response?.message as string
          });

          reset(state);
          onSuccess$?.();
        }
      }
    });

    return (
      <div class="relative flex w-full h-[327px] flex-col items-center justify-start gap-5 mt-8">
        <div class="flex flex-col gap-7 self-stretch">
          <Form class="flex w-full h-full justify-center items-center flex-col gap-5">
            <div class="max-w-[365px] w-full mx-auto">
              <Field name="email">
                {(field, props) => (
                  <input
                    {...props}
                    disabled={state.submitting}
                    type="hidden"
                    value={field.value}
                  />
                )}
              </Field>
              <Field name="code">
                {(field, props) => (
                  <Input
                    {...props}
                    class="focus:placeholder-neutral-300"
                    disabled={state.submitting}
                    placeholder="Código de segurança*"
                    size="lg"
                    state={
                      (field.touched && state.invalid) || messageError.value
                        ? field.error || messageError.value
                          ? 'error'
                          : 'success'
                        : 'default'
                    }
                    type="text"
                    value={field.value}
                    {...(action.value?.response?.status &&
                      action.value?.response?.status === 'error' && {
                        state: 'error',
                        label:
                          action.value.response.message || messageError.value
                      })}
                  />
                )}
              </Field>

              <p class="mt-2 text-sm text-slate-400 [&>strong]:text-slate-700">
                <Count
                  prefix={data.requestCode.timeTitle}
                  suffix="segundo(s)"
                  trigger={restartTrigger.value}
                  onFinish={$(() => (enableRequestCode.value = true))}
                />
              </p>
            </div>
            <Button
              class="max-w-[365px] w-full self-center active:ring-0 focus:ring-0"
              disabled={!enableRequestCode.value || state.submitting}
              loading={loadingRequestCode.value}
              loadingText="Gerando código"
              size="xl"
              type="button"
              variant="outline"
              onClick$={handleRequestCode$}
            >
              {data.requestCode.requestCodeTitle}
            </Button>
            <Button
              class="max-w-[365px] w-full self-center"
              buttonText={data.requestCode.buttonTitle}
              disabled={state.invalid || state.submitting || !state.dirty}
              loading={state.submitting}
              size="xl"
              type="submit"
              variant="brand"
            />
          </Form>
        </div>
      </div>
    );
  }
);

const Count = component$(
  ({
    prefix,
    timeRemaining,
    suffix,
    onFinish,
    trigger
  }: {
    prefix: string;
    timeRemaining?: number;
    suffix?: string;
    onFinish?: () => void;
    trigger: number;
  }) => {
    const initialTime = useSignal(timeRemaining ?? 60);
    const seconds = useSignal(initialTime.value);

    // eslint-disable-next-line qwik/no-use-visible-task
    useVisibleTask$(({ cleanup, track }) => {
      track(() => trigger);

      seconds.value = initialTime.value;

      const interval = setInterval(() => {
        seconds.value--;

        if (seconds.value <= 0) {
          clearInterval(interval);
          onFinish?.();
        }
      }, 1000);

      cleanup(() => clearInterval(interval));
    });

    return seconds.value > 0 ? (
      <>
        {prefix} <strong>{seconds.value}</strong> {suffix}
      </>
    ) : (
      <>O código expirou. Solicite um novo no botão abaixo</>
    );
  }
);

const requestCode = $(
  async () =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 3000);
    })
);
