/* eslint-disable @typescript-eslint/no-explicit-any */
import history from 'history-events';
import {
  $,
  component$,
  useComputed$,
  useOnWindow,
  useSignal,
  useVisibleTask$
} from '@builder.io/qwik';
import { Button } from '../ui/Button';
import { ChangePasswordForm } from './ChangePasswordForm';
import { Icon } from '../ui/Icon';
import {
  IconDefinition,
  faArrowLeft,
  faTimes
} from '@fortawesome/pro-light-svg-icons';
import { Image } from '../ui/Image';
import { RecoveryEmailForm } from './RecoveryEmailForm';
import { RequestCodeForm } from './RequestCodeForm';
import { SigninEmailForm } from './SigninEmailForm';
import { SignupEmailForm } from './SignupEmailForm';
import { flagVariants } from '../../routes/layout';
import { useAuthDataLoader, useCurrentHref } from './utils';
import { useAuthModal } from './AuthModal';
import { useNavigate } from '@builder.io/qwik-city';
import { usePreline } from '../../hooks/use-preline.hook';

export type AuthScreenId =
  | 'signin'
  | 'signin-email'
  | 'signup'
  | 'signup-email'
  | 'recovery'
  | 'requestCode'
  | 'changePassword';

export const Auth = component$(
  ({
    variations,
    onSuccess$
  }: {
    variations?: flagVariants;
    onSuccess$?: () => void;
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }) => {
    const { store, on } = useAuthModal();
    const storedValues = useSignal<string | undefined>();
    const { value: data } = useAuthDataLoader();
    const currentHref = useCurrentHref();
    const nav = useNavigate();
    const showBanner = false; // TODO: Change this in the future when banner is fixed.

    const screenData = useComputed$(() => {
      const path = store.screen.split('-')[0] as keyof typeof data;

      return data[path] as unknown as {
        title: string;
        subtitle: string;
        changeScreenQuestion: string;
        changeScreenQuestionLinkTitle: string;
        terms?: string;
      };
    });

    const handleHashChange = $(() => {
      const url = new URL(location.href);
      const hash = url?.hash?.replaceAll?.(/^\/|^#!?\/?|\/$/g, '') ?? 'signin';

      if (hash) {
        store.screen = hash as AuthScreenId;
      }
    });

    const handleChangeModeClick = $(() => {
      const target = store.screen.includes('signup') ? 'signin' : 'signup';

      store.screen = target;

      nav(`${currentHref.value}#!/${target}`, {
        type: 'link',
        replaceState: true
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSuccess = $(async (value?: any) => {
      const prevCurrentRoutePath = store.screen;
      const currentScreen = currentHref.value.split('#')[0];

      if (value) {
        storedValues.value = value;
      }

      if (prevCurrentRoutePath === 'recovery') {
        store.screen = 'requestCode';
        return;
      }

      if (prevCurrentRoutePath === 'requestCode') {
        store.screen = 'changePassword';
        return;
      }

      if (prevCurrentRoutePath === 'changePassword') {
        store.screen = 'signin-email';
        return;
      }

      store.screen = 'signin';
      nav(currentScreen);

      if (typeof onSuccess$ !== 'function') {
        return;
      }

      onSuccess$();
    });

    // eslint-disable-next-line qwik/no-use-visible-task
    useVisibleTask$(({ track }) => {
      track(() => [currentHref.value, store.screen]);
      nav(`${currentHref.value.split('#')[0]}#!/${store.screen}`);
    });

    usePreline(
      $(() => {
        on('open', () => {
          nav(currentHref.value.split('#')[0]);
        });

        on('close', () => {
          nav(currentHref.value.split('#')[0]);
          store.screen = 'signin';
        });
      })
    );

    useOnWindow(
      'load',
      $(() => {
        if (history.isHistorySupported()) {
          window.addEventListener('changestate', () => {
            handleHashChange();
          });
        }

        handleHashChange();
      })
    );

    return (
      screenData.value && (
        <div class="pointer-events-auto w-full md:w-[535px] max-h-[580px] relative">
          {showBanner && (
            <div class="relative -mt-3 flex h-full min-h-px w-full sm:min-w-[245px] items-center overflow-hidden rounded-b-2xl text-center md:-mr-3 md:mt-0 md:max-w-[245px] md:rounded-l-2xl md:rounded-r-none">
              <Image
                alt={data.banner.alt}
                desktopHeight={580}
                desktopSrc={data.banner.image.desktop}
                desktopWidth={245}
                fit="cover"
                mobileHeight={580}
                mobileSrc={data.banner.image.mobile}
                mobileWidth={480}
                tabletHeight={580}
                tabletSrc={data.banner.image.tablet}
                tabletWidth={245}
              />
            </div>
          )}

          <div class="rounded-2xl bg-white w-full h-full px-9 lg:px-12 py-4 pt-9 lg:pt-8 lg:py-8">
            <div class="flex w-full h-full flex-col items-center justify-between gap-7">
              <div class="flex w-full h-full flex-col">
                <div class="flex flex-col gap-2 lg:gap-5 self-start">
                  <div class="h-[4px] w-full max-w-[36px] rounded-[90px] bg-slate-800" />
                  <div class="flex w-full flex-col gap-2">
                    <p class="text-primary-600 text-2xl lg:text-3xl font-semibold">
                      {screenData.value.title}
                    </p>
                    <p class="max-w-[440px] text-sm lg:text-md text-slate-500">
                      {screenData.value.subtitle}
                    </p>
                  </div>
                </div>
                <div class="w-full max-w-[365px] mx-auto flex-1">
                  {store.screen === 'signin-email' ? (
                    <>
                      <SigninEmailForm
                        enableRecovery={variations?.enableRecoveryPassword}
                        reload
                        onSuccess$={handleSuccess}
                      />
                      <ModalActionIcon
                        icon={faArrowLeft}
                        url={`${currentHref.value}#!/signin`}
                      />
                    </>
                  ) : store.screen === 'signup-email' ? (
                    <>
                      <SignupEmailForm reload onSuccess$={handleSuccess} />
                      <ModalActionIcon
                        icon={faArrowLeft}
                        url={`${currentHref.value}#!/signup`}
                      />
                    </>
                  ) : store.screen === 'recovery' ? (
                    <>
                      <RecoveryEmailForm onSuccess$={handleSuccess} />
                      <ModalActionIcon
                        icon={faArrowLeft}
                        url={`${currentHref.value}#!/signin-email`}
                      />
                    </>
                  ) : store.screen === 'requestCode' ? (
                    <>
                      <RequestCodeForm
                        email={storedValues.value}
                        onSuccess$={handleSuccess}
                      />
                      <ModalActionIcon
                        icon={faArrowLeft}
                        url={`${currentHref.value}#!/recovery`}
                      />
                    </>
                  ) : store.screen === 'changePassword' ? (
                    <>
                      <ChangePasswordForm
                        email={storedValues.value}
                        onSuccess$={handleSuccess}
                      />
                      <ModalActionIcon
                        icon={faArrowLeft}
                        url={`${currentHref.value}#!/requestCode`}
                      />
                    </>
                  ) : (
                    <>
                      <InitialScreen screen={store.screen} />
                      <button
                        class="absolute right-5 top-5 text-neutral-400"
                        onClick$={handleSuccess}
                      >
                        <Icon class="text-xl" icon={faTimes} />
                      </button>
                    </>
                  )}
                </div>
                {screenData.value.terms && (
                  <p
                    class="text-center text-xs font-medium text-slate-500 mt-4 [&>a]:no-underline [&>a]:font-semibold"
                    dangerouslySetInnerHTML={screenData.value.terms}
                  />
                )}
              </div>
              <div class="flex w-full flex-col items-center justify-start gap-4">
                <div class="w-full border-t border-slate-300" />
                <p class="text-center text-sm lg:text-md font-medium text-slate-600">
                  {screenData.value.changeScreenQuestion}{' '}
                  <button
                    class="text-primary-600 font-semibold"
                    onClick$={handleChangeModeClick}
                  >
                    {screenData.value.changeScreenQuestionLinkTitle}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
);

const ModalActionIcon = component$(
  ({ url, icon }: { url: string; icon: IconDefinition }) => (
    <a class="absolute right-5 top-5 text-neutral-400" href={url}>
      <Icon class="text-xl" icon={icon} />
    </a>
  )
);

export const InitialScreen = component$(
  ({ screen }: { screen: AuthScreenId }) => {
    const { value: data } = useAuthDataLoader();
    const currentHref = useCurrentHref();

    return (
      <div class="flex w-full flex-col gap-5 mt-9">
        <div class="flex w-full max-w-[365px] mx-auto flex-col items-center justify-start gap-4 lg:gap-8">
          <div class="flex w-full flex-col gap-5">
            <a
              href="https://www.qconcursos.com/conta/auth/google_oauth2"
              onClick$={$(() => {
                if (window?.dataLayer) {
                  window['dataLayer'].push({
                    event: screen === 'signin' ? 'login' : 'sign_up',
                    method: 'Social'
                  });
                }
              })}
            >
              <Button
                class="w-full lg:h-[50px] min-w-0 gap-2.5 transition-shadow hover:drop-shadow-xl hover:bg-white"
                size="lg"
                variant="outline"
              >
                <GoogleIcon />
                <span class="text-sm lg:text-base font-semibold text-slate-600">
                  {screen === 'signin'
                    ? data.enterWithGoogle
                    : data.createWithGoogle}
                </span>
              </Button>
            </a>
            <a
              href="https://www.qconcursos.com/conta/auth/facebook"
              onClick$={$(() => {
                if (window?.dataLayer) {
                  window['dataLayer'].push({
                    event: screen === 'signin' ? 'login' : 'sign_up',
                    method: 'Social'
                  });
                }
              })}
            >
              <Button
                class="w-full lg:h-[50px] gap-2.5 transition-shadow hover:drop-shadow-xl hover:bg-white"
                size="lg"
                variant="outline"
              >
                <FacebookIcon />
                <span class="text-sm lg:text-base font-semibold text-slate-600">
                  {screen === 'signin'
                    ? data.enterWithFacebook
                    : data.createWithFacebook}
                </span>
              </Button>
            </a>
          </div>
          <div class="flex w-full items-center justify-center gap-4">
            <div class="flex-1 border-t border-slate-200" />
            <p class="text-sm font-medium text-slate-600">ou</p>
            <div class="flex-1 border-t border-slate-200" />
          </div>
          <a class="w-full" href={`${currentHref.value}#!/${screen}-email`}>
            <Button
              class="w-full lg:h-[50px] gap-2.5 transition-shadow hover:drop-shadow-xl hover:bg-white"
              size="lg"
              variant="outline"
            >
              <EmailIcon />
              <span class="text-sm lg:text-base font-semibold text-slate-600">
                {screen === 'signin'
                  ? data.enterWithEmail
                  : data.createWithEmail}
              </span>
            </Button>
          </a>
        </div>
      </div>
    );
  }
);

export const GoogleIcon = () => (
  <svg
    class="min-w-[24px]"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0632 12.2235C22.0632 11.3961 21.9947 10.7924 21.8464 10.1663H12.2061V13.9005H17.8647C17.7507 14.8285 17.1346 16.2261 15.7655 17.1652L15.7464 17.2902L18.7945 19.6044L19.0056 19.625C20.9451 17.8696 22.0632 15.2869 22.0632 12.2235Z"
      fill="#4695A6"
    />
    <path
      d="M12.2058 22.0624C14.9781 22.0624 17.3054 21.1679 19.0054 19.6251L15.7653 17.1652C14.8983 17.7578 13.7345 18.1715 12.2058 18.1715C9.49056 18.1715 7.18603 16.4162 6.36452 13.99L6.24411 14L3.07465 16.4039L3.0332 16.5168C4.72167 19.8039 8.18992 22.0624 12.2058 22.0624Z"
      fill="#34A853"
    />
    <path
      d="M6.36501 13.9902C6.14825 13.3641 6.02281 12.6932 6.02281 12C6.02281 11.3068 6.14825 10.636 6.35361 10.0099L6.34787 9.87652L3.13869 7.43408L3.03369 7.48303C2.33779 8.84707 1.93848 10.3788 1.93848 12C1.93848 13.6212 2.33779 15.1529 3.03369 16.5169L6.36501 13.9902Z"
      fill="#FBBC05"
    />
    <path
      d="M12.2059 5.82831C14.1339 5.82831 15.4345 6.64448 16.1761 7.32654L19.0739 4.55375C17.2942 2.93257 14.9782 1.9375 12.2059 1.9375C8.18995 1.9375 4.72168 4.19595 3.0332 7.48301L6.35314 10.0099C7.18605 7.58368 9.4906 5.82831 12.2059 5.82831Z"
      fill="#EB4335"
    />
  </svg>
);

export const FacebookIcon = () => (
  <svg
    class="min-w-[24px]"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_387_1138)">
      <path
        d="M23.5 12C23.5 5.64872 18.3513 0.5 12 0.5C5.64872 0.5 0.5 5.64872 0.5 12C0.5 17.7399 4.70536 22.4976 10.2031 23.3603V15.3242H7.2832V12H10.2031V9.46641C10.2031 6.58422 11.92 4.99219 14.5468 4.99219C15.8047 4.99219 17.1211 5.2168 17.1211 5.2168V8.04688H15.671C14.2425 8.04688 13.7969 8.93341 13.7969 9.84375V12H16.9863L16.4765 15.3242H13.7969V23.3603C19.2946 22.4976 23.5 17.7399 23.5 12Z"
        fill="#1877F2"
      />
      <path
        d="M16.4765 15.3242L16.9863 12H13.7969V9.84375C13.7969 8.93431 14.2425 8.04688 15.671 8.04688H17.1211V5.2168C17.1211 5.2168 15.8051 4.99219 14.5468 4.99219C11.92 4.99219 10.2031 6.58422 10.2031 9.46641V12H7.2832V15.3242H10.2031V23.3603C11.3938 23.5466 12.6062 23.5466 13.7969 23.3603V15.3242H16.4765Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_387_1138">
        <rect width="23" height="23" fill="white" rx="11.5" x="0.5" y="0.5" />
      </clipPath>
    </defs>
  </svg>
);

export const EmailIcon = () => (
  <svg
    class="min-w-[21px]"
    width="21"
    height="18"
    fill="none"
    viewBox="0 0 21 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.916992 4.20825L8.74171 9.68555C9.37533 10.1291 9.69214 10.3509 10.0367 10.4368C10.3411 10.5126 10.6595 10.5126 10.9639 10.4368C11.3085 10.3509 11.6253 10.1291 12.2589 9.68555L20.0837 4.20825M5.51699 16.6666H15.4837C17.0938 16.6666 17.8989 16.6666 18.5139 16.3532C19.0548 16.0776 19.4947 15.6378 19.7703 15.0968C20.0837 14.4818 20.0837 13.6767 20.0837 12.0666V5.93325C20.0837 4.3231 20.0837 3.51803 19.7703 2.90303C19.4947 2.36206 19.0548 1.92224 18.5139 1.64661C17.8989 1.33325 17.0938 1.33325 15.4837 1.33325H5.51699C3.90684 1.33325 3.10177 1.33325 2.48677 1.64661C1.9458 1.92224 1.50598 2.36206 1.23035 2.90303C0.916992 3.51803 0.916992 4.3231 0.916992 5.93325V12.0666C0.916992 13.6767 0.916992 14.4818 1.23035 15.0968C1.50598 15.6378 1.9458 16.0776 2.48677 16.3532C3.10177 16.6666 3.90684 16.6666 5.51699 16.6666Z"
      stroke="#475467"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.57843"
    />
  </svg>
);
